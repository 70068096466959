import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  navigateToAndroidUrl(){
    window.open("https://play.google.com/apps/testing/com.giza.nova.business", "_blank");
  }

  navigateToIosUrl(){
    window.open("https://testflight.apple.com/join/4Qujm0DU", "_blank");
  }

}
