import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  isHome: boolean = true;
  isDownload: boolean = false;
  isTerms: boolean = false;
  public showLogoText: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  public changeActiveState(state: number) {
    switch (state) {
      case 2:
        this.isHome = false;
        this.isDownload = true;
        this.isTerms = false;
        break;

      case 3:
        this.isHome = false;
        this.isDownload = false;
        this.isTerms = true;
        break;

      default:
        this.isHome = true;
        this.isDownload = false;
        this.isTerms = false;
        break;
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    window.innerWidth < 345 ? this.showLogoText = false : this.showLogoText = true;
  }

}
