import { Component, OnInit, ViewChild, Output } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }

  navigateToAndroidUrl(){
    window.open("https://play.google.com/apps/testing/com.giza.nova.business", "_blank");
  }

  navigateToIosUrl(){
    window.open("https://testflight.apple.com/join/4Qujm0DU", "_blank");
  }

}
