import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RouterModule } from '@angular/router';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FooterComponent } from './footer/footer.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { FeaturesComponent } from './features/features.component';
import { DownloadComponent } from './download/download.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    HomePageComponent,
    TermsAndConditionsComponent,
    PageNotFoundComponent,
    FooterComponent,
    ComingSoonComponent,
    FeaturesComponent,
    DownloadComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxPageScrollCoreModule.forRoot({ duration: 2500 }),
    RouterModule.forRoot([
      { path: 'en/home', component: HomePageComponent },
      { path: 'en/download', component: DownloadComponent },
      { path: 'en/terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'en/coming-soon', component: ComingSoonComponent },
      { path: '', redirectTo: 'en/home', pathMatch: 'full' },
      { path: '**', component: PageNotFoundComponent }
    ], { anchorScrolling: 'enabled' })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
